import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App/App';

import { applyMiddleware,createStore } from 'redux';
import { Provider } from 'react-redux';
import reducers from './redux/reducers';

import * as serviceWorker from './serviceWorker';
import DatabaseApi from './Services/dbApi';

DatabaseApi.initDatabase();
const store = createStore(reducers, applyMiddleware());


ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, 
  document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
